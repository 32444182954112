<template>
  <div>
    <b-card>
      <template #header>
        UPC Generator Config
        <span class="float-right">
          <b-button size="sm" variant="secondary" @click="() => $router.push('/upc-generator')">Back</b-button>
          <b-button class="ml-1" size="sm" variant="primary" @click="onFormViewModal('add', null)">Add Config</b-button>
        </span>
      </template>
      <b-card-text>
        <div class="filter d-flex justify-content-between">
        <div class="left-controls">
          <b-button :disabled="updateSelectedItemsLoading || selectedItems.length === 0" variant="success" @click="onOpenStatus">
            {{ updateSelectedItemsLoading ? 'Opening Status' : 'Open Status' }}
          </b-button>
          <span class="ml-2">{{ selectedItems.length }} item(s) selected.</span>
        </div>
        <div class="right-controls">
          <b-form-group label="Search" label-cols-lg="auto">
            <b-form-input v-model.trim="search" placeholder="Brand or Sheet" type="search"></b-form-input>
          </b-form-group>
        </div>
        </div>
        <div class="table">
          <b-table
          id="upc-generator-table"
          class="border-bottom"
          small
          show-empty
          :filter="search"
          :filter-included-fields="['brand', 'sheet']"
          @filtered="onFiltered"
          :empty-text="tableLoading ? 'Getting Data...' : 'There are no records to show'"
          :busy="tableLoading"
          :items="items"
          :fields="fields"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          stacked="sm"
          select-mode="multi"
          selectable
          selected-variant=""
          hover>
            <template #head(selected)="">
              <b-form-checkbox @change="onSelectAll" /> 
            </template>

            <template #cell(selected)="data">
              <b-form-checkbox :checked="data.item.selected" @change="(event) => onRowSelect(event, data.item)" />
            </template>

            <template #cell(status)="data">
              <b-badge v-if="data.item.status === 1" variant="success" class="py-2" style="width: 5rem;">Synced</b-badge>
              <b-badge v-if="data.item.status === 0" variant="secondary" class="py-2" style="width: 5rem;">Open</b-badge>
            </template>

            <template #cell(updatedAt)="data">
              <span v-if="data.item.updatedAt" class="py-2">{{ data.item.updatedAt }}</span>
              <b-badge v-else variant="secondary" class="py-2" style="width: 5rem;">Null</b-badge>
            </template>

            <template #cell(actions)="data">
              <b-button class="mr-1" size="sm" variant="primary" @click="onFormViewModal('update', data.item)">View Config</b-button>
              <b-button size="sm" variant="danger" @click="onDelete(data.item)">Delete</b-button>
            </template>

          </b-table>
          <b-pagination
          :disabled="tableLoading"
          v-if="items.length > 0"
          v-model="pagination.currentPage"
          :total-rows="totalRows"
          :per-page="pagination.perPage"
          aria-controls="upc-generator-table"
          align="center"
          first-number
          last-number
          hide-goto-end-buttons
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal v-model="formModal" :title="formType === 'add' ? 'Add' : 'Update'" hide-footer no-close-on-backdrop @close="onFormCancelModal">
      <b-card-text>
        <b-form-group label="Enter Brand:" label-for="form-brand" >
          <b-form-input id="form-brand" v-model="formData.brand"></b-form-input>
        </b-form-group>
        <b-form-group label="Enter Sheet:" label-for="form-sheet" >
          <b-form-input id="form-sheet" v-model="formData.sheet"></b-form-input>
        </b-form-group>
        <b-form-group label="Enter Config:" label-for="form.config" >
          <b-form-textarea id="form-config" v-model="formData.config" rows="10" class="config-editor"></b-form-textarea>
        </b-form-group>
        <b-form-group class="border-top mb-n1">
          <b-button class="mt-2" v-if="formType === 'update'" variant="warning" size="sm" @click="copyToClipboard">Copy to Clipboard</b-button>
          <div class="controls float-right mt-2">
            <b-button :disabled="formLoading" class="mr-1" size="sm" variant="secondary" @click="onFormCancelModal">Cancel</b-button>
            <b-button :disabled="formLoading" size="sm" variant="primary" @click="onFormSubmit">Submit</b-button>
          </div>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import api from '../services/apiService'
export default {
  data(){
    return {
      //import modal
      formModal: false,
      formLoading: false,
      formType: 'add',
      formData: {
        id: null,
        brand: '',
        sheet: '',
        config: ''
      },
      //table
      search: '',
      items: [],
      fields: [
        {
          key: "selected",
          label: "",
          class: 'align-middle text-center'
        },
        {
          key: "createdAt",
          label: "Date Created",
          class: 'align-middle'
        },
        {
          key: "brand",
          label: "Brand",
          class: 'align-middle'
        },
        {
          key: "sheet",
          label: "Sheet",
          class: 'align-middle'
        },
        {
          key: "status",
          label: "Status",
          class: 'align-middle'
        },
        {
          key: "updatedAt",
          label: "Last Date Synced",
          class: 'align-middle'
        },
        {
          key: "actions",
          label: "Actions",
          class: 'align-middle'
        }
      ],
      tableLoading: false,
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0
      },

      filteredItems: [],
      selectedItems: [],
      updateSelectedItemsLoading: false
    }
  },
  computed: {
    totalRows(){
      return this.pagination.totalRows > 0 ? this.pagination.totalRows : this.items.length
    }
  },
  created(){
    this.loadItems();
  },
  methods: {
    onFiltered(filteredItems){
      if(filteredItems.length > 0){
        this.filteredItems = filteredItems
      } else {
        this.filteredItems = []
      }

      this.pagination.totalRows = filteredItems.length
      this.pagination.currentPage = 1
    },
    async onOpenStatus(){
      this.updateSelectedItemsLoading = true
      try {
        let syncedStatusIds = this.selectedItems.filter(e => e.status === 1).map(e => e.id)
        if(syncedStatusIds.length > 0){
          let response = await api.put(`/api/upc-generator/configs`, JSON.stringify({ brandIds: syncedStatusIds }));
          this.$bvToast.toast(response.data.message, {
            title: `Updated`,
            variant: 'success',
            solid: true
          })
          this.selectedItems = []
          await this.loadItems()
        } else {
          this.$bvToast.toast("Can't proceed! Selected items are open.", {
            title: `Error`,
            variant: 'warning',
            solid: true
          })
        }
        this.updateSelectedItemsLoading = false
      } catch (error) {
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
        this.updateSelectedItemsLoading = false
      }
    },
    onSelectAll(val){
      let data = []
      if(val && this.filteredItems.length === 0){ 
        data = this.items.map(e => {
          return { ...e, selected: true }
        })
        this.selectedItems = data
      }else{
        data = this.items.map(e => {
          return { ...e, selected: false }
        })
        this.selectedItems = []
      }
      this.items = data
    },
    onRowSelect(event, data){
      if(event){
        this.selectedItems.push(data)
        data.selected = true
      }else{
        this.selectedItems = this.selectedItems.filter(e => e.id !== data.id)
        data.selected = false
      }
    },
    onFormViewModal(type, data){
      this.formModal = true
      if(type === 'add'){
        this.formType = 'add'
        this.formData = { id: null, brand: '', sheet: "", config: '' }
      }else if(type === 'update'){
        this.formType = 'update'
        this.formData = {
          id: data.id,
          brand: data.brand,
          sheet: data.sheet,
          config: JSON.stringify(JSON.parse(data.config), undefined, 4)
        }
      }
    },
    onFormCancelModal(){
      this.formModal = false
      this.formData = { id: null, brand: "", sheet: "", config: "" }
    },
    async onFormSubmit(){
      this.formLoading = true
      try {
        let response;
        let data = {
          brand: this.formData.brand,
          sheet: this.formData.sheet,
          config: JSON.parse(this.formData.config)
        }
        if(this.formType === 'add'){
          response = await api.post(`/api/upc-generator/configs`, JSON.stringify(data));
        } else if(this.formType === 'update'){
          response = await api.put(`/api/upc-generator/configs/${this.formData.id}`, JSON.stringify(data));
        }
        
        this.$bvToast.toast(response.data.message, {
          title: `Success`,
          variant: 'success',
          solid: true
        })
        this.formLoading = false
        this.onFormCancelModal();
        await this.loadItems();
      } catch (error) {
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
        this.formLoading = false
      }
    },
    async loadItems(){
      this.items = []
      this.tableLoading = true
      let response = await api.get('/api/upc-generator/configs')
      this.items = response.data.map(e => {
        return { ...e, selected: false }
      })
      this.pagination = {
        ...this.pagination,
        totalRows: response.data.length
      }
      this.tableLoading = false
    },
    async onDelete(data){
      let isConfirmed = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this config?', {
        title: 'Confirm',
        buttonSize: 'sm',
        okTitle: 'Confirm',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false
      })
      if(isConfirmed){
        let response = await api.delete(`/api/upc-generator/configs/${data.id}`)
        this.$bvToast.toast(response.data.message, {
          title: 'Success',
          variant: 'success',
          solid: true
        })
        await this.loadItems()
      }
    },
    async copyToClipboard(){
      await navigator.clipboard.writeText(this.formData.config);
      this.$bvToast.toast("Copied to clipboard", {
          title: 'Success',
          variant: 'success',
          solid: true
        })
    },
  }
}
</script>